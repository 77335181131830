import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AgencyInvitationsHttpService } from '@slice-services/agency-invitation/agency-invitations-http.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { PaymentMethod } from '@slice-interfaces/agency-client-roster/agency-client-roster-item';
import {
  InviteCreatorFormParams,
  ResponseInviteCreator,
} from '@slice-interfaces/agency-invitation/agency-invite-payload';
import { CreatorKYCRole } from '@slice-enums/user-types.enum';

@Component({
  selector: 'slice-modal-csv-creator',
  templateUrl: './modal-csv-creator.component.html',
  styleUrls: ['./modal-csv-creator.component.scss'],
})
export class ModalCsvCreatorComponent extends AbstractSubscriberComponent {
  @Output() closed = new EventEmitter<boolean>();
  public isVisible = true;
  public isVisibleResponse = false;
  public isVisibleDialogFix = false;
  public isLoading = false;
  public isDragOver = false;
  public responseData: ResponseInviteCreator;

  selectedFile: File | null = null;
  jsonData: any;

  constructor(
    private tS: TranslocoService,
    private agencyInvitationsHttpS: AgencyInvitationsHttpService,
    private messageS: MessageService,
    private authStateS: AuthStateService,
  ) {
    super();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
  }

  onFileDropped(event: any): void {
    event.preventDefault();
    this.isDragOver = false;
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const droppedFile = droppedFiles[0];
      const allowedFileTypes = [
        'application/vnd.ms-excel', // For older Excel files (.xls)
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // For newer Excel files (.xlsx)
      ];

      if (allowedFileTypes.includes(droppedFile.type)) {
        // check file size max 8MB
        if (droppedFile && droppedFile.size > 8388608) {
          this.messageS.add({
            summary: this.tS.translate(
              'file-upload.file-pdf.error-toastr.title',
            ),
            severity: 'info',
            life: 8 * 1000,
            detail: this.tS.translate(
              'file-upload.file-pdf.error-toastr.big-size',
            ),
          });
          return;
        }
        this.selectedFile = droppedFile;
      } else {
        this.messageS.add({
          severity: 'error',
          life: 5 * 1000,
          detail: 'Please drop a valid Excel file.',
        });
      }
    }
  }

  onFileSelected(input: any): void {
    this.selectedFile = input.files[0] as File;
  }

  readFile(): void {
    if (this.selectedFile) {
      this.processFile(this.selectedFile);
    }
  }

  processFile(file: File): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[1]; // Read the first sheet
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      // console.log('Excel Data:', data);
      this.jsonData = this.formatData(data);
      // console.log('Formatted Data:', this.jsonData);
      this.submitData(this.jsonData);
    };

    reader.readAsBinaryString(file);
  }

  formatData(data: any[]): any[] {
    if (this.checkHeaders(data[0])) {
      const formattedData: any[] = data.slice(2).map(row => ({
        name: row[0] || null, // Full Name, required or default to empty string
        email: row[1] || null, // Email, required
        waPhoneNumber: this.convertPhoneNumber(row[2]) || null, // WhatsApp Phone Number, required
        address: row[3] || null, // Address (Optional)
        idCardNumber: row[4] || null, // ID Card No (NIK) (Optional)
        kycType: row[5] ? row[5]?.toUpperCase() : CreatorKYCRole.INDIVIDUAL, // Account Type (Default INDIVIDUAL)
        companyName: row[6] || null, // Company Name (Optional)
        npwpNumber:
          row[5]?.toUpperCase() === CreatorKYCRole.COMPANY ? row[7] : row[8], // NPWP Company or NPWP Individual
        bankCode: row[9] || null, // Bank Code mapped from Bank Account
        bankTransfer: row[10] || null, // Bank Account (Optional)
        bankAccountNumber: row[11] || null, // Bank Account Number (Optional)
        paymentMethod: row[9]
          ? row[9] === 'slice'
            ? PaymentMethod.SLICE_WALLET
            : PaymentMethod.BANK_TRANSFER
          : null, // Active Payment Methods
        earnings: row[12]?.toString() || null, // Current Annual Earnings (Optional)
      }));

      // Filter out entries missing required fields (e.g., name, email)
      return formattedData.filter(entry => entry.name && entry.email);
    } else {
      this.messageS.add({
        severity: 'error',
        life: 5 * 1000,
        detail: 'Invalid Excel template. Please use the provided template.',
      });
      return [];
    }
  }

  checkHeaders(array: any[]): boolean {
    const expectedHeaders = [
      'Full Name',
      'Email',
      'Whatsapp Phone Number',
      'Address (Optional)',
      'ID Card No (NIK)\n(Optional)',
      'Account Type\n(Optional)',
      'Tax Identifier Number (NPWP)\n(Optional)',
      null,
      null,
      'Bank Code',
      'Bank Account\n(Optional)',
      'Bank Account Number\n(Optional)',
      'Current Annual Earnings \n(Optional)',
    ];
    return array.every(
      (header: string, index: number) => header === expectedHeaders[index],
    );
  }

  convertPhoneNumber(phone: any): string {
    if (!phone) return '';
    if (phone.startsWith('+62')) return phone.substring(1); // Remove '+'
    if (phone.startsWith('0')) return '62' + phone.substring(1); // Replace leading 0 with 62
    return phone; // Return as-is
  }

  closeResponseModal(): void {
    this.isVisibleResponse = false;
    this.jsonData = {};
    this.closed.emit();
  }

  eventErrorFixModal(v?: any[]): void {
    if (v) {
      this.submitData(v);
    } else {
      this.isVisibleDialogFix = false;
      this.jsonData = {};
      this.closed.emit();
    }
  }

  downloadFile(): void {
    const filePath = '/assets/creator-upload-template.xlsx';

    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'creator-upload-template.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  submitData(payload: Array<InviteCreatorFormParams>) {
    if (payload.length > 0) {
      this.isLoading = true;
      this.agencyInvitationsHttpS
        .inviteUsersBulk(
          this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
          payload,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (r: ResponseInviteCreator) => {
            this.isLoading = false;
            this.isVisible = false;

            // assign response value into dialog
            if (this.responseData) {
              r.totalInvited += this.responseData?.totalInvited ?? 0;
              r.totalAddedToRoster +=
                this.responseData?.totalAddedToRoster ?? 0;
              this.responseData = r;
            } else {
              this.responseData = r;
            }

            // open the respective dialog based on the result
            if (r.invalidCreators.length) {
              this.isVisibleDialogFix = true;
            } else {
              this.isVisibleResponse = true;
            }
          },
          (err: Error) => {
            this.isLoading = false;
            if (err) {
              if (this.isVisibleDialogFix) {
                this.messageS.add({
                  severity: 'error',
                  life: 5 * 1000,
                  detail: 'Failed to invite creator',
                });
              } else {
                this.messageS.add({
                  severity: 'error',
                  life: 5 * 1000,
                  detail: this.tS.translate('common.error-500'),
                });
              }
            }
          },
        );
    } else {
      this.messageS.add({
        severity: 'error',
        life: 5 * 1000,
        detail: 'No creator data in the excel file',
      });
    }
  }
}
