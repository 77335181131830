<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="false"
  [dismissableMask]="true"
  (onHide)="triggerHide()"
>
  <p-header></p-header>
  <div class="content-wrap" *ngIf="currConfig && isVisible">
    <div>
      <h5 class="text-center my-1 font-weight-bold">Congratulations!</h5>
      <div class="d-flex flex-column justify-content-center">
        <span class="text-center mt-4">
          You have successfully import
          <b>{{ data.totalInvited + data.totalAddedToRoster }}</b> creator
          <ng-container *ngIf="data.totalAddedToRoster">
            <br />
            <b>{{ data.totalInvited }}</b> new creators
            <br />
            <b>{{ data.totalAddedToRoster }}</b>
            creator is already inside your roster
          </ng-container>
          <br />
          <br />
          Now It's time to take the next step. Start a campaign
          <br />
          and set up payments smoothly. Let's get things moving!
        </span>
        <!--<ul
          style="list-style: disc; display: inline-block"
          class="align-self-center ml-3 my-1"
        >
          <li>
            <span class="font-weight-bold">{{ data.totalInvited || 0 }} </span
            >creators are successfully invited to your Roster
          </li>
        </ul>
        <ul
          style="list-style: disc; display: inline-block"
          class="ml-3 my-1 align-self-center"
        >
          <li>
            <span class="font-weight-bold"
              >{{ data.totalAddedToRoster || 0 }} </span
            >creators are already in your Roster
          </li>
        </ul>
        <ul
          style="list-style: disc; display: inline-block"
          class="ml-3 my-1 align-self-center"
        >
          <li>
            <span class="font-weight-bold"
              >{{ data.totalInvalidCreators || 0 }} </span
            >creators are invalid
          </li>
        </ul>-->
      </div>
    </div>
    <button class="slc-btn slc-btn-primary my-4 w-auto" (click)="goCampaign()">
      Start a Campaign
    </button>
    <!--<button class="slc-btn slc-btn-primary my-4 w-auto" (click)="gotoRoster()">
      Go To Creator Roster
    </button>
    <p class="text-center" *ngIf="data.invalidCreators?.length > 0">
      However, we found list which have invalid data and can’t be invited. Make
      sure there is no typographical error. You may import these creators again
      later.
    </p>
    <table class="custom-table my-3" *ngIf="data.invalidCreators?.length > 0">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Current Earnings (IDR)</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.invalidCreators">
          <td
            [ngClass]="{
              'red-color': row?.errorCodes?.includes(
                AgencyInvitationErrorStatus.INVALID_NAME
              )
            }"
          >
            {{ row.name }}
          </td>
          <td
            [ngClass]="{
              'red-color': row?.errorCodes?.includes(
                AgencyInvitationErrorStatus.INVALID_EMAIL
              )
            }"
          >
            {{ row.email }}
          </td>
          <td
            [ngClass]="{
              'red-color': row?.errorCodes?.includes(
                AgencyInvitationErrorStatus.INVALID_EARNINGS
              )
            }"
          >
            {{ row.earnings }}
          </td>
        </tr>
      </tbody>
    </table>-->
  </div>
</p-dialog>
