import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { NavigationService } from '@slice-services/navigation.service';
import { NgOtpInputConfig } from 'ng-otp-input';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { ResponseInviteCreator } from '@slice-interfaces/agency-invitation/agency-invite-payload';

import { AgencyInvitationErrorStatus } from '../../../../../../../../libs/enums/src/lib/agency-invitation-status';

// import { ChangePinFacadeService } from '../services/change-pin-facade.service';

interface Config {
  imgPath: string;
  titleKey?: string;
  descriptionKey?: string;
  mainBtnKey?: string;
  additionalBtnKey?: string;
  showInfoIcon?: boolean;
  showSupportBtn?: boolean;
  showAnnualPaymentBtn?: boolean;
  logout?: boolean;
}

@Component({
  selector: 'slice-modal-csv-creator-response',
  templateUrl: './modal-csv-creator-response.component.html',
  styleUrls: ['./modal-csv-creator-response.component.scss'],
})
export class ModalCsvCreatorResponseComponent extends AbstractSubscriberComponent {
  @Input() isVisible = false;
  @Input() data: ResponseInviteCreator;
  @Output() closed = new EventEmitter<boolean>();

  @HostBinding('style.--image') image = '';
  @ViewChild('verificationCodes', { static: false }) verificationCodes: any;
  private config: Config = {
    imgPath: "url('/assets/images/modal-header/invite-creator-success.png')",
    titleKey: 'invoice-response-modal.invoice-response-success.title',
    descriptionKey:
      'invoice-response-modal.invoice-response-success.description',
    mainBtnKey: 'invoice-response-modal.invoice-response-success.main-btn',
  };

  countdownDisplay: string;
  countdownFinished = false;
  AgencyInvitationErrorStatus = AgencyInvitationErrorStatus;

  public currConfig: Config;
  // isVisible = false;
  isLoading = false;
  email: string;
  verificationCodeControl: FormControl;
  form: FormGroup;
  otpConfig: NgOtpInputConfig = {
    length: 6,
    placeholder: '_',
    inputStyles: {
      width: '60px',
      'margin-top': '5px',
    },
  };

  constructor(private fb: FormBuilder, private navigationS: NavigationService) {
    super();
    this.currConfig = this.config;
    this.image = this.config.imgPath;
  }

  triggerHide(): void {
    // this.facadeS.setIsModalVerification(false);
    this.closed.emit();
  }

  goCampaign(): void {
    this.triggerHide();
    this.navigationS.goToReports();
  }

  gotoRoster(): void {
    this.triggerHide();
    this.navigationS.goToAgencyRoster();
  }
}
